//import log from "loglevel";
import { useState, Fragment } from 'react'
import { PlusCircleIcon, CreditCardIcon, BanknotesIcon} from '@heroicons/react/20/solid'
import TransactionEdit from "@components/TransactionEdit";
import { formatPrice } from "@shared/Utils";
import TransactionTable from "@components/TransactionTable";
import {  Transition, Dialog, DialogPanel, TransitionChild} from '@headlessui/react'
import { CheckIcon, ClockIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import  TillDetails  from '@components/TillDetails2';
import useTill from "@hooks/useTill";
import useStallholder from "@hooks/useStallholder";
import {CASH, DEBIT, CREDIT, CONTRA, CONTRA_CASH} from '@constants/payment_types'
import {DESCRIPTION_MIN_LENGTH} from '@constants/defaults'
import { useIsOnline } from 'react-use-is-online';
import { Switch, Field, Label } from '@headlessui/react'
import classnames from 'classnames';

export function TransactionEntry() {

  const [showOptions, setShowOptions] = useState("invisible");
  const [showDialog, setShowDialog] = useState(false);
  const [showDealer, setShowDealer] = useState(true);
  const [total, completePurchase, purchases, addItemToSale, description, price, stock, dealer, isQPitch, isCNP, setPrice, setDealer, setStock, setDescription, setIsQPitch, setIsCNP, processingPurchases, resetTill] = useTill();
  const stallholderContext = useStallholder();
  const { isOnline } = useIsOnline();

  /*
  function reset() {
    //setPurchases([]);
    window.location.reload();
  }*/

  function reset() {

    resetTill();
    setShowDialog(false);
    setShowDealer(true);
  }

  function dialogClosed () {
    reset();
  }

  async function complete(paymentType: number) {
    setShowDialog(true);
    await completePurchase(paymentType, isCNP);
  }

  function addItemToSaleAndResetScreen() {
    addItemToSale();
    setShowDealer(true);
  }

  function getCardButtonsDisabled() {

    if (description.length < DESCRIPTION_MIN_LENGTH) return true;
    if (stallholderContext.stallholder === undefined) return true;
    //if (!isOnline) return true;
    return false;
  }

  function getCashButtonsDisabled() {

    if (getCardButtonsDisabled()) return true;
    if (isCNP) return true;
    return false;
  }

  function getDisabledReason() {

    if (description.length < DESCRIPTION_MIN_LENGTH) return "Description is shorter than " + DESCRIPTION_MIN_LENGTH + " characters";
    if (stallholderContext.stallholder === undefined) return "The till operator is not set";
    if (!isOnline) return "The iPad is offline";
    if (isCNP) return "Cash buttons disabled for CNP";
    return "   ";
  }

  const handleCNPChange = (state: boolean) => {
    setIsCNP(state);
  };

  return (
    <div>      
      <CompleteDialog isOpen={showDialog} processingPurchases={processingPurchases} close={dialogClosed}/>
      <div className="relative flex items-center justify-between">    
        <div>
          <TillDetails />
        </div>
        <div>
          <button
            onClick={reset}
            className="text-slate-400 rounded-full bg-white px-2.5 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Start again
          </button>
        </div>
      </div>
      <div className="h-dvh">

        <div className="grid grid-cols-1 justify-items-center">
          <TransactionEdit price={price} 
                          stock={stock} 
                          description={description} 
                          dealer={dealer} 
                          isQPitch={isQPitch}
                          setPrice={setPrice}
                          setDescription={setDescription}
                          setStock={setStock}
                          setDealer={setDealer}
                          showDealer={showDealer}
                          setIsQPitch={setIsQPitch}
                          setShowDealer={setShowDealer}
                          showFavs={true}
            >
            <div className="grid grid-cols-1 justify-items-center">
              <button 
                  disabled={description.length < DESCRIPTION_MIN_LENGTH}
                  onClick={addItemToSaleAndResetScreen}//{()=>{setShowDescription(false)}}
                  className="flex mt-10 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 border border-emerald-600 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                <PlusCircleIcon className="w-8 h-8 mr-5"/>
                Add another item
              </button>

              <h1 className="mt-8 text-2xl">or sale complete & select payment type...</h1>
              <div>
                <div className="grid grid-cols-2 ">
                  <button 
                    disabled={getCardButtonsDisabled()}
                    onClick={() => {complete(DEBIT)}}
                    className="flex ml-5 mt-10 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-purple-500 border border-purple-600 p-4 text-white shadow-sm hover:bg-purple-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    <CreditCardIcon className="w-8 h-8 mr-5"/>
                    Debit
                  </button>
                  <button 
                    disabled={getCardButtonsDisabled()}
                    onClick={() => {complete(CREDIT)}}
                    className="flex ml-5 mt-10 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-fuchsia-500 p-4 border border-fuchsia-600 text-white shadow-sm hover:bg-fuchsia-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    <CreditCardIcon className="w-8 h-8 mr-5"/>
                    Credit
                  </button>

                  <button 
                    disabled={getCashButtonsDisabled()}
                    onClick={() => {complete(CASH)}}
                    className="flex ml-5 mt-5 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-cyan-500 p-4 border border-cyan-600 text-white shadow-sm hover:bg-cyan-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                  >
                    <BanknotesIcon className="w-8 h-8 mr-5"/>
                    Cash
                  </button>
                  <div className="flex justify-end">
                    <button 
                        className="flex ml-5 mr-5 mt-6 w-16 h-16 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-white p-4 border border-slate-600 text-white shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"

                        onClick={() => {(showOptions === "visible") ? setShowOptions("invisible") : setShowOptions("visible");}}
                      >
                        <ChevronDownIcon className="mr-1 h-8 w-8 text-slate-600"  />
                    
                    </button>
                  </div>
                </div>
              </div>
              {
                getCashButtonsDisabled() || getCardButtonsDisabled()?
                <p className="mt-4 text-red-600 font-bold text-2xl">{getDisabledReason()}</p>:
                <p className="mt-4 text-white font-bold text-2xl">fff</p>
              }
              <div className={showOptions}>
                <div className="bg-white shadow">
                  <div className="px-1 py-1">
                    <div className="flex ">
                      <button 
                        disabled={getCashButtonsDisabled()}
                        onClick={() => {complete(CONTRA)}}
                        className="flex ml-5 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-lime-50 border border-lime-100 p-4 text-lime-950 shadow-sm hover:bg-lime-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                      >
                        <BanknotesIcon className="w-8 h-8 mr-5"/>
                        Contra
                      </button>
                      <button 
                        disabled={getCashButtonsDisabled()}
                        onClick={() => {complete(CONTRA_CASH)}}
                        className="flex ml-5 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-lime-50 border border-lime-100 p-4 text-lime-950 shadow-sm hover:bg-lime-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                      >
                        <BanknotesIcon className="w-8 h-8 mr-5"/>
                        <div>
                          Cash Contra
                        </div>
                        
                      </button>
                    </div>
                    <div className="mt-1 grid grid-cols-1 justify-center">
                      <Field as="div" className="mt-4 flex items-center justify-end">
                        <span className="mr-4">
                          <Label as="span" className="mt-4 font-medium leading-6 text-gray-900" passive>
                          Cardholder not present
                          </Label>
                        </span>
                        <Switch
                          checked={isCNP}
                          onChange={handleCNPChange}
                          className={classnames(
                            isCNP ? 'bg-indigo-600' : 'bg-gray-200',
                            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                          )}
                        >
                          <span
                            aria-hidden="true"
                            className={classnames(
                              isCNP ? 'translate-x-5' : 'translate-x-0',
                              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                            )}
                          />
                        </Switch>
                      </Field>
                    </div>
                  </div>
                </div> 
              </div> 
            </div>  
          </TransactionEdit>

        </div>       
        <div className="mt-72">
          {
            
            purchases.length > 0 ?
            <div className="grid justify-items-center ">
              <span className="text-4xl font-semibold">Total: £{formatPrice(total)} </span>
              <div className="px-4 py-5 sm:p-6">
                <TransactionTable data={purchases} />
              </div>
            </div> :
            <></>
            
          }
        </div>
      </div>
    </div>
  );
}

type CompleteDialogProps = {
  isOpen: boolean;
  processingPurchases: boolean;
  close: () => void
};

const CompleteDialog = ({ isOpen, processingPurchases, close }: CompleteDialogProps) => {

  return (

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={()=>{}}>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <DialogPanel className="max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-2">
                  {
                    processingPurchases 
                    ?
                      <div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full border-2 border-red-600 bg-red-100">
                        <ClockIcon className="h-20 w-20 text-red-600"  />
                      </div> 
                    :

                      <div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full border-2 border-green-600 bg-green-100">
                        <CheckIcon className="h-20 w-20 text-green-600"  />
                      </div> 
                  }
                  </div>        

                  <div className="mt-4">
                    <button
                      type="button"
                      className="mt-10 px-20 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 disabled:border-red-900 border-1 border-emerald-900 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={close}
                      disabled={processingPurchases}
                    >
                      {
                        processingPurchases 
                        ?
                          <>Processing</>
                        :
                          <>Next Order</>
                      }
                      
                    </button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
  )
}

export default TransactionEntry;
