import { useState, useEffect } from 'react'
import { parsePrice } from "@shared/Utils";
import { DeleteButton, NumberButton } from '@shared/Buttons';
import {ZERO} from "@hooks/useTill";

const POINT = "."

type CashPadProps = {
  initialPrice: string;
  setter: (number: string) => void
};

const CashPad = ({ initialPrice, setter }: CashPadProps) => {

  const [price, setPrice] = useState<string>(initialPrice);
  const [format] = useState("bg-indigo-600 hover:bg-indigo-500 border-1 border-indigo-900");


  useEffect(() => {

    if (initialPrice !== price) {
      setPrice(initialPrice);
    }
    

    return () => {
    };
  }, [initialPrice]); 

  useEffect(() => {

    setter(price);

    return () => {
    };
  }, [price, setter]); 

  const options = { 
    minimumFractionDigits: 0,
    maximumFractionDigits: 2 
  };

  function appendNumber(number: string) {

    if (parseFloat(price) === 0) {
      
      if (price.indexOf(POINT) === price.length - 1) {
        setPrice(price + number);
      }
      else /*if (number.localeCompare('0') !== 0)*/ {
        setPrice(number);
      }
    
    }
    else {
      const index = price.indexOf(POINT);
      
      if (index > 0 ) {
        if (price.length - index <= 2) {
          setPrice(price + number);
        }
      }
      else {
        let priceNoCommas = price.replace(/,/g, '');   
        setPrice(Number(parseFloat(parsePrice(priceNoCommas) + number)).toLocaleString('en', options));
      }
    }
  }

  function appendPoint(number: string) {

    const index = price.indexOf(".");

    if (price.localeCompare(ZERO) === 0) {
      setPrice("0.");
    }
    // prevents multiple points
    else if (index < 0) {
      setPrice(price + POINT);
    }
  }

  function deleteNumber() {

    if (price === ZERO) {

    }
    else if (price.length > 1) {
      setPrice(price.substring(0, price.length-1));
    }
    else
      setPrice(ZERO);
  }

  return(
    <div className="grid justify-items-center ">

      <div>
        <p className="text-4xl font-extrabold">£{price}</p>
      </div>
      

      <div className="grid grid-cols-3 gap-4 w-80 mt-12">
        <NumberButton value="1" onClick={appendNumber} classOverrides={format}/>
        <NumberButton value="2" onClick={appendNumber} classOverrides={format}/>
        <NumberButton value="3" onClick={appendNumber} classOverrides={format}/>
        <NumberButton value="4" onClick={appendNumber} classOverrides={format}/>
        <NumberButton value="5" onClick={appendNumber} classOverrides={format}/>
        <NumberButton value="6" onClick={appendNumber} classOverrides={format}/>
        <NumberButton value="7" onClick={appendNumber} classOverrides={format}/>
        <NumberButton value="8" onClick={appendNumber} classOverrides={format}/>
        <NumberButton value="9" onClick={appendNumber} classOverrides={format}/>
        <NumberButton value={POINT} onClick={appendPoint} classOverrides="bg-blue-500 hover:bg-blue-400"/>
        <NumberButton value="0" onClick={appendNumber} classOverrides={format}/>
        <DeleteButton onClick={deleteNumber} />
      </div>

    </div>);
}


export default CashPad;
