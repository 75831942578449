import React, { ReactNode, useState, useEffect, useContext } from 'react'
import {  QuestionMarkCircleIcon, 
          InformationCircleIcon, 
          UserGroupIcon, 
          CurrencyPoundIcon,
          MicrophoneIcon,
          XMarkIcon
        } from '@heroicons/react/20/solid'
import StallholderSearch2 from "@components/StallholderSearch2";
import CashPad from "@components/CashPad";
import { focusAndOpenKeyboard } from "@shared/Utils";
import { Stallholder} from '@sharedtypes/Stallholder';
import {  Transition} from '@headlessui/react'
import useStallholderHistory from "@hooks/useStallholderHistory";
import EnforceStockIDsContext from "@contexts/EnforceStockIDsContext";
import log from "loglevel";
import { Field, Label, Switch } from '@headlessui/react'
import classnames from 'classnames';
import FloorContext from "@contexts/FloorContext";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import useStallholders from "@hooks/useStallholders";
import { formatPrice, parsePrice } from "@shared/Utils";
import { Cache } from 'aws-amplify/utils';

const VISIBLE = "visible z-10";
const INVISIBLE = "invisible z-0 h-0";
const LISTEN = "listen";

type TransactionEditProps = {
  description: string;
  price: string;
  stock: string;
  dealer: Stallholder | undefined; 
  isQPitch: boolean,
  setPrice: (price: string) => void;
  setDealer : (stallholder: Stallholder | undefined) => void;
  setDescription: (description: string) => void;
  setStock: (stock: string) => void;
  setIsQPitch: (isQPitch: boolean) => void;
  children?: ReactNode;
  showDealer: boolean;
  setShowDealer: (showDealer: boolean) => void;
  showFavs: boolean;
};

const TransactionEdit = ({  description, 
                            price, 
                            stock, 
                            dealer, 
                            isQPitch,
                            setPrice, 
                            setDealer, 
                            setStock, 
                            setDescription, 
                            children, 
                            showDealer, 
                            setShowDealer,
                            setIsQPitch,
                            showFavs}: TransactionEditProps) => {

  const {enforce} = useContext(EnforceStockIDsContext);
  const {floor} = useContext(FloorContext);

  const [showPrice, setShowPrice] = useState(false);

  const [transcriptIndex, setTranscriptIndex] = useState(0);

  const [visiblePrice, setVisiblePrice] = useState(VISIBLE);
  const [visibleStock, setVisibleStock] = useState(INVISIBLE);
  const [visibleDescription, setVisibleDescription] = useState(INVISIBLE);

  const stockInput = React.useRef<HTMLInputElement>(null);
  const descriptionInput = React.useRef<HTMLInputElement>(null);

  const {addToHistory} = useStallholderHistory();
  const stallholdersContext = useStallholders();
  const [showVoiceTips, setShowVoiceTips] = useState(false);


  const {
    //resetTranscript,
    transcript,
    listening,
    browserSupportsSpeechRecognition,
    browserSupportsContinuousListening
  } = useSpeechRecognition();

  const [listen, setListen] = useState(false);


  useEffect(() => {

    if (listen && browserSupportsSpeechRecognition) {
      log.debug("start listening");
      SpeechRecognition.startListening({ continuous: true });
    }
    else if (!listen){
      log.debug("listening not supported");
      SpeechRecognition.stopListening();
    }
    // returned function will be called on component unmount 
    return () => {
      log.debug("unmounted")
      if (browserSupportsSpeechRecognition) {
        log.debug("stop listening");
        //resetTranscript();
        SpeechRecognition.stopListening();
      }
    }
  }, [listen, browserSupportsSpeechRecognition]);

  useEffect(() => {

    Cache.getItem(LISTEN)
    .then(result => {
      if(result) {
        setListen(result);
      }
    })
    return () => {
    };
  }, []);   

  useEffect(() => {

    Cache.setItem(LISTEN, listen);
  
    return () => {
    };
  }, [listen]);   

  useEffect(() => {

    log.debug("Listening? " + listening);
    log.debug("Continuous listening? " + browserSupportsContinuousListening);

    return () => {
    };
  }, [listening, browserSupportsContinuousListening]); 

  useEffect(() => {

    log.debug("transcript: " + transcript);
    const parts = transcript.split(' ');
    var last = parts[parts.length-1].toLowerCase();
    log.debug("transcript 2: ", last);

    if (last === "next") {
      processNext();
    }
    else {
      processValue(last, transcript);
    }

    return () => {
    };
  }, [transcript]); 

  useEffect(() => {

    if (showDealer) {
      setTranscriptIndex(0);
      setShowPrice(false);
      setShowDealer(true);
      setTimeout(function() {
        
        switchToPriceVisible();
      }, 100);

    }
    return () => {
    };
  }, [showDealer, setShowDealer]); 

  function toggleListen() {

    //not exactly a toggle because can be closed separately 
    // and then would open when turning off voice
    if (!listen) {
      setShowVoiceTips(true);
    }
    else {
      setShowVoiceTips(false);
    }
    
    setListen(!listen);
  }

  function processNext() {

    if (showDealer) {
      if (dealer !== undefined) {
        dealerSelected();
        setShowPrice(true);
      }
    }
    else if (showPrice) {

      if (visiblePrice === VISIBLE) {
        if (Number(price) > 0)
          switchToStockVisible();
      }
      else if (visibleStock === VISIBLE) {
        if (!enforce || stock.length > 0)
         switchToDescriptionVisible();
      }
    }
  }

  function processValue(last: string, transcript: string) {

    if (showDealer) {
      const stallholder = stallholdersContext.findStallholderByID(last, false);
      if (stallholder)
        setDealer(stallholder);

    }
    else if (showPrice) {

      if (visiblePrice === VISIBLE) {

        if (last.charAt(0) === "£") last = last.substring(1, last.length);
        if (last.charAt(last.length-1) === "p") { 
          if (last.length === 3) {
            last = "00." + last.substring(0, last.length-1);
          }
          else if (last.length === 2) {
            last = "00.0" + last.substring(0, last.length-1);
          }
        }
        if (isPrice(last)) {
          const price = parsePrice(last);
          const formattedPrice = formatPrice(price);
          setPrice(formattedPrice);
        }

      }
      else if (visibleStock === VISIBLE) {
        var index = Math.max(transcript.toLowerCase().lastIndexOf("clear") + 6, transcriptIndex);
        setStock(transcript.substring(index));
      }
      else {
        var index2 = Math.max(transcript.toLowerCase().lastIndexOf("clear") + 6, transcriptIndex);
        setDescription(transcript.substring(index2));
      }
    }
  }

  function switchToPriceVisible() {
    setVisiblePrice(VISIBLE);
    setVisibleStock(INVISIBLE);
    setVisibleDescription(INVISIBLE);
  }

  function switchToStockVisible() {

    setTranscriptIndex(transcript.length);
    //resetTranscript();
    setVisiblePrice(INVISIBLE);
    setVisibleStock(VISIBLE);
    focusAndOpenKeyboard(stockInput.current);
  }

  function switchToDescriptionVisible() {
    setTranscriptIndex(transcript.length);
    //resetTranscript();
    setVisibleStock(INVISIBLE);
    setVisibleDescription(VISIBLE);
    focusAndOpenKeyboard(descriptionInput.current);
  }

  const handleStockChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const { value } = event.target;
    setStock(value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const { value } = event.target;
    setDescription(value);
  };

  function setDealerFromFav(stallholder: Stallholder | undefined) {

    log.debug("setDealerFromFav", stallholder);
    setDealer(stallholder);
    setShowDealer(false);
  }

  function dealerSelected() {
    setShowDealer(false);
    /// placed here because want to do when sure that is right dealer number else it captures 
    /// partial dealer numbers eg i put in 345 but it treats 34 as part of the history if 34 is a valid dealer number    if (dealer !== undefined)
    if (dealer !== undefined)
      addToHistory(dealer);
  }

  const handleQPitchChange = (state: boolean) => {
    log.debug("handleQPitchChange", state);
    setIsQPitch(state);
  };

  const labelFormat = "text-2xl leading-6 text-gray-900 mb-6";

  return (
    <div>
      <div className="absolute z-10 top-36 left-10 rounded-lg">
        <button
          onClick={toggleListen}
        >
          {
            listen ?
            <MicrophoneIcon className="rounded-full px-2.5 py-2.5 shadow-sm ring-1 ring-inset ring-gray-300  h-16 w-16 text-emerald-700" aria-hidden="true" /> :
            <MicrophoneIcon className="rounded-full px-2.5 py-2.5 shadow-sm ring-1 ring-inset ring-gray-300  h-16 w-16 text-gray-400" aria-hidden="true" />

          }
        </button>
      </div>
      <div className="absolute z-10 top-56 left-10 rounded-lg">
        {
          showVoiceTips ?
          <VoiceTips close={()=>setShowVoiceTips(false)}/> :
          <></>
        }
      </div>
      
      {
        (showDealer && showFavs) ? <Favs setDealer={setDealerFromFav}/> : <></>
      }
      <div className="h-96 grid grid-cols-1 justify-items-center ">
        <Transition 
          as="div"
          show={showDealer}
          enter="transition-opacity ease-linear duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => {setShowPrice(true)}}
        >
          <div className="flex justify-center">
            <UserGroupIcon className="mr-2 h-6 w-6 text-indigo-600"/>
            <p className={labelFormat}>Enter the dealer ID</p>
          </div>
          <StallholderSearch2 stallholder={dealer} setter={setDealer} />
          <div className="flex justify-center">
            <button 
              onClick={dealerSelected}
              disabled={dealer === undefined}
              className="mt-10 px-20 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 disabled:border-red-900 border-1 border-emerald-900 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Next
            </button>
          </div>
        </Transition>


        <Transition
          as="div"
          show={showPrice}
          enter="transition-opacity ease-linear duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={visiblePrice}>
            <div className="flex justify-center">
              <CurrencyPoundIcon className="mr-2 h-6 w-6 text-indigo-600"/>
              <p className={labelFormat}>Enter the price</p>
            </div>
            <CashPad setter={setPrice} initialPrice={price}/>
            <div className="flex justify-center">
              <button 
                onClick={switchToStockVisible}
                disabled={Number(price) === 0}
                className="mt-10 px-20 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 disabled:border-red-900 border-1 border-emerald-900 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Next
              </button>
            </div>
          </div>
        
          <div className={visibleStock}>
            <label htmlFor="stock-id" className={labelFormat}>
              Enter the stock ID
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
            
              <input
                value={stock}
                type="text"
                ref={stockInput} 
                name="stock"
                id="stock"
                className= "block border-0 w-full rounded-md py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-2xl"             
                onChange={handleStockChange}
                placeholder="0000"
              />
              
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
            </div>
            <div>
              {
                floor === 0 ?
                <Field as="div" className="mt-4 flex items-center justify-end">
                <span className="mr-4">
                  <Label as="span" className="font-medium leading-6 text-gray-400" passive>
                  Sold from Q Pitch
                  </Label>
                </span>
                <Switch
                  checked={isQPitch}
                  onChange={handleQPitchChange}
                  className={classnames(
                    isQPitch ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classnames(
                      isQPitch ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </Field> :
              <></>
              }

            </div>
            <div className="flex justify-center">
              <button 
                disabled={enforce && stock.length === 0}
                onClick={switchToDescriptionVisible}
                className="mt-10 px-20 font-extrabold text-2xl rounded-full disabled:bg-red-600 bg-emerald-500 disabled:border-red-900 border-1 border-emerald-900 p-4 text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                Next
              </button>
            </div>
          </div>          

          <div className={visibleDescription}>
            <div >
              <label htmlFor="description" className={labelFormat}>
                Add a description
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  value={description}
                  type="text"
                  ref={descriptionInput} 
                  name="description"
                  id="description"
                  onChange={handleDescriptionChange}
                  //onFocus={callClick}
                  className="block border-0 w-full rounded-md py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-2xl"    
                  placeholder="e.g. An oak cabinet"
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <InformationCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </div>
            </div>   
            <div>
              <div className="grid grid-cols-1 justify-items-center">
                {children}
              </div>  
            </div> 
          </div>
        </Transition>
      </div>
    </div>
  );
}

type FavsProps = {
  setDealer: (stallholder:Stallholder | undefined) => void
};

const Favs = ({ setDealer }: FavsProps) => {

  const {history} = useStallholderHistory();

  return (
    <>
      {
        history.length > 0 
        ?
        <div className="absolute z-10 top-36 right-20 rounded-lg">
        <div className="rounded-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
          <label htmlFor="name" className="block text-xs font-medium text-gray-900">
            Favourites
          </label>
          <div className="grid grid-cols-2 gap-2 p-3">
            {
              history.map((stallholder: Stallholder) => (
                <div key={stallholder.dealer_id}>
                  <button 
                    className="rounded-full border-1 border-pink-900 w-12 h-12 bg-pink-500 p-1.5 text-white shadow-sm hover:bg-pink-700  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={()=>setDealer(stallholder)}
                    >
                    {stallholder.dealer_id}
                  </button>
                </div>
              ))
            } 
          </div>
        </div>
      </div>
      :
      <></>
      }

    </>
  )
}

function isPrice(value: string) {

  const number = parseFloat(value);
  if (isNaN(number)) return false;
  if (countDecimals(number) > 2 )return false;
  return true;
}

var countDecimals = function (value: number) {
  if(Math.floor(value) === value) return 0;
  return value.toString().split(".")[1].length || 0; 
}

type VoiceTipsProps = {
  close: () => void
};

const VoiceTips = ({ close }: VoiceTipsProps) => {

  return (
    <div>
      <div className="bg-white w-48 rounded-md pl-4 border-2 border-slate-300 shadow-xl text-left">
        <div className="absolute right-0 top-0 hidden pr-1 pt-1 sm:block">
          <button
            type="button"
            onClick={() => close()}
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span className="sr-only">Close</span>
            <XMarkIcon aria-hidden="true" className="size-8" />
          </button>
        </div>
        <h2 className="font-bold mt-8">
          Tips for using voice
        </h2>
        <ul className="list-disc p-2">
          <li>
            All standard fields can be filled by speaking.
          </li>
          <li>
            You will still need to touch the screen at the end for payment, or to add another item.
          </li>
          <li>
            The word 'Next' clicks the buttons.
          </li>
          <li>
            The word 'Clear', clears the stock and description fields.
          </li>
          <li>
            If the wrong thing is entered in price or dealer, just repeat.
          </li>
          <li>
            To stop annoying permissions dialog, change to 'Allow' for Safari in Settings.
          </li>
        </ul>
      </div>
    </div>
  )
}

export default TransactionEdit;
