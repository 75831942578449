import log from "loglevel";
import { useState, useContext, useEffect } from "react";
import useTillAPIService from "@hooks/useTillAPIService";
import useStallholder from "@hooks/useStallholder";
import FloorContext from "@contexts/FloorContext";
import { Stallholder} from '@sharedtypes/Stallholder';
import { parsePrice } from "@shared/Utils";

export type TillItem = {
  stallholder: string;
  description: string;
  stock: string;
  price: number;
  isQPitch: boolean;
};    

export const ZERO = "0.00"

type TillQueryType = (
  //completePurchase: () =>{},
) => any;

const useTill: TillQueryType = () => {
  
  const [purchases, setPurchases] = useState<TillItem[]>([])
  const [transactionIndex, setTransactionIndex] = useState(0);
  const [total, setTotal] = useState<number>(0);
  const apiService  = useTillAPIService();
  const stallholderContext = useStallholder();
  const {floor} = useContext(FloorContext);
  const [price, setPrice] = useState<string>(ZERO);
  const [isQPitch, setIsQPitch] = useState(false);
  const [isCNP, setIsCNP] = useState(false);
  const [dealer, setDealer] = useState<Stallholder | undefined>(undefined);
  const [stock, setStock] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [processingPurchases, setProcessingPurchases] = useState(false);

  useEffect(() => {

    if ( dealer !== undefined) {
      const newPurchases = [...purchases];

      if (newPurchases.length === transactionIndex) {
        newPurchases.push({price: 0.00 , stallholder: dealer.dealer_id, description:"", stock:"", isQPitch:false});
      }
      else {
        purchases[transactionIndex].stallholder = dealer.dealer_id;
      }

      setPurchases(newPurchases);   
    }
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealer]); 
  
  useEffect(() => {

    const priceAsNumber = parsePrice(price);

    if (priceAsNumber > 0 && purchases.length > 0) {

      purchases[transactionIndex].price = priceAsNumber;
      const newPurchases = [...purchases];
      setPurchases(newPurchases);  
      calculateTotal();
    }
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price]); 

  useEffect(() => {

    if (stock !== undefined && stock.length > 0 && purchases.length > 0) {

      purchases[transactionIndex].stock = stock;
      const newPurchases = [...purchases];
      setPurchases(newPurchases);  
    }
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stock]); 

  useEffect(() => {

    if (description !== undefined && description.length > 0 && purchases.length > 0) {

      purchases[transactionIndex].description = description;
      const newPurchases = [...purchases];
      setPurchases(newPurchases);  
    }
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]); 

  useEffect(() => {

    if ( purchases.length > 0) {

      purchases[transactionIndex].isQPitch = isQPitch;
      const newPurchases = [...purchases];
      setPurchases(newPurchases);  
    }
    return () => {
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQPitch]); 

  function resetTill() {

    setPurchases([]);
    setTransactionIndex(0);
    setTotal(0);
    setPrice(ZERO);
    setIsQPitch(false);
    setIsCNP(false);
    setDealer(undefined);
    setStock("");
    setDescription("");
    setProcessingPurchases(false);
  }

  function calculateTotal() {

    var total = 0.00;
  
    purchases.forEach((tillItem: TillItem) => {
        
      total = total + tillItem.price;
  
    });
    setTotal(total);
  }

  function completePurchase(paymentType: number, isCNP: boolean ) {
    
    if (apiService !== null) {
      log.info("apiService not null", purchases.length);

      if (purchases.length > 0) {
        if (stallholderContext.stallholder !== undefined) {

          setProcessingPurchases(true);

          const promise = apiService.addTransactions(purchases, floor, stallholderContext.stallholder.dealer_id, paymentType, isCNP);
      
          promise.then(async function(value) {
      
            log.info("response from transactions posting : " + JSON.stringify(value.data));
          })
          .catch(function(error) {
            console.error(error);
          })    
          .finally(() => {
            setProcessingPurchases(false);
            log.debug('transaction completed');
          });
        }
      }
    }
    else {
      log.info("apiService null");
    }
  }

  function addItemToSale() {

    setTransactionIndex(transactionIndex+1);
    setPrice(ZERO);
    setDealer(undefined);
    setIsQPitch(false);
    setDescription("");
    setStock("");
    
  }

  return [total, completePurchase, purchases, addItemToSale, description, price, stock, dealer, isQPitch, isCNP, setPrice, setDealer, setStock, setDescription, setIsQPitch, setIsCNP, processingPurchases, resetTill];
}

export default useTill;
